import 'bootstrap';
import {boostrap} from 'bootstrap';




import { gsap } from "gsap";

import barba from '@barba/core';

const myUrl = window.location.href;
const namefields = document.getElementsByClassName('name');



var urlParams = new URL(myUrl);
var username = urlParams.searchParams.get('name')
if (username == null) {
    // Wenn es kein name in der URL gibt
    console.log('Keine Personalisierte Nachricht');
} else {
    // Wenn es einen Namen in der URL gibt
    console.log('Dein Name ist ' +username);
    var title = 'Wichte Infos über ' + username + ' - guck mal hier!'
    var descrption = 'Sie werden nicht glauben was für eine Nachrich hier auf ' + username + ' wartet!'
    document.querySelector('meta[property="og:title"]').setAttribute("content", title);
    document.querySelector('meta[property="og:url"]').setAttribute("content", myUrl);
    document.querySelector('meta[name="twitter:description"]').setAttribute("content", descrption);
    document.querySelector('meta[name="twitter:title"]').setAttribute("content", title);
    document.title = "Fick dich einfach " + username + ".";

    for (let i = 0; i < namefields.length; i++) {
        const element = namefields[i];
        console.log(element);
        element.innerText = username;
    }
}


function pagetransition() {
  var tl = gsap.timeline();
  tl.to('ul.transitition li', {duration: .5, height: '100vh', transformOrigin: 'bottom left', stagger: .2});
  tl.to('ul.transitition li', {duration: .5,  height: '0vh', transformOrigin: 'bottom left', stagger: .1, delay: .1});
}


function contentAnimation() {
  var tl = gsap.timeline();
  tl.to('.imganimation', {clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)"});
  tl.from('.left', { duration: 1.5, translateX: 50, opacity: 0})
  tl.from('.bottomanimation', { duration: 1.5, translateY: 50, opacity: 0}, '-=.8')
}


function delay(n) {
  n = n | 2000;
  return new Promise(done => {
    setTimeout(() => {
      done();
    }, n)
  });
}


// barba.init({
//     sync: true,
//     transitions: [{
//         async leave() {
//           // create your stunning leave animation here
//           const done = this.async();
//           pagetransition();
//           await delay(1400);
//           done();
//         },
//         async enter() {
//           // create your amazing enter animation here
//           contentAnimation()
//         },
//         async once() {
//           // create your amazing enter animation here
//           contentAnimation()
//         }
//       }]
// });



